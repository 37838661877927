import { useContext, useCallback, useEffect } from 'react';
import { observer } from "mobx-react";
import { StoreContext } from '../../../../store';
import { Link } from "react-router-dom";


import styles from './SignInForm.module.css';
import cn from 'classnames';

function SignInForm() {
  const globalStore = useContext(StoreContext);

  useEffect(() => {
    return () => {
      globalStore.models.signInModel.handleError();
      globalStore.models.signInModel.clear();
    };
  }, [globalStore.models.signInModel]);

  const updateInfo = useCallback((event) => {
    globalStore.models.signInModel.updateInfo(event.currentTarget.name, event.currentTarget.value);
  }, [globalStore.models.signInModel]);

  const handleSubmit = useCallback((event) => {
    globalStore.services.signInService.signIn(event);
  }, [globalStore.services.signInService]);

  const isError = globalStore.models.signInModel.isError;

  return (
    <section className={styles.authFormContainer}>
      <nav className={styles.nav}>
        <div className={styles.linkContainer}>
          <p className={styles.linkText}>
            Еще не с нами?
            <Link className={styles.link} to="/signup"> Зарегистрироваться</Link>
          </p>
        </div>
      </nav>
      <main className={styles.main}>
        <h1 className={styles.heading}>Войти</h1>
        <form className={styles.signUpForm} onSubmit={handleSubmit}>
          <div className={styles.formField}>
            <fieldset className={styles.inputContainer}>
              <label className={styles.label}>Никнейм или Почта</label>
              <input className={cn(styles.inputText, isError && styles.inputError)}
                type="text"
                name="login"
                onChange={updateInfo}
                value={globalStore.models.signInModel.info.login}
                required
              />
            </fieldset>
          </div>
          <div className={styles.formField}>
            <fieldset className={styles.inputContainer}>
              <label className={styles.label}>Пароль</label>
              <input className={cn(styles.inputText, isError && styles.inputError)}
                type="password"
                name="password"
                onChange={updateInfo}
                value={globalStore.models.signInModel.info.password}
                required
              />
            </fieldset>
          </div>
          {isError &&
            <div className={styles.errorField}>
              <p className={styles.errorText}>Не можем найти такого пользователя :(</p>
            </div>}
          <div className={cn(styles.formField, styles.btnGroup)}>
            <button type="submit" className={styles.inputBtn}>Войти</button>
            {/* <GoogleLoginBtn /> */}
          </div>
        </form>
      </main>
    </section>
  )
}

export default observer(SignInForm);
