import { observable, action, makeObservable } from "mobx";

interface Inputs {
  name: string,
  description: string,
  date: string,
  color: string,
}


class ModalNewProjectCardModel {
  
  constructor() {
    makeObservable(this);
  }
  
  @observable isOpen: boolean = false;

  @observable inputs: Inputs = {
    name: '',
    description: '',
    date: '',
    color: '',
  };

  @action updateInputs(type: string, value: string) {
    this.inputs = {
      ...this.inputs,
      [type]: value,
    };
  }

  @action setColor(color: string) {
    this.inputs.color = color;
  }

  @action clear() {
    this.inputs = {
      name: '',
      description: '',
      date: '',
      color: '',
    };
  }

  @action open() {
    this.isOpen = true;
  }

  @action close() {
    this.isOpen = false;
  }
}

export default ModalNewProjectCardModel;
