import { toJS } from 'mobx';
import axios from 'axios';

import history from '../../history/history';

import { ModalChangeBigTaskModel, NewBigTaskModel, ProjectModel } from '../models';

class ProjectService {
  private projectModel: ProjectModel;
  private newBigTaskModel: NewBigTaskModel;
  private modalChangeBigTaskModel: ModalChangeBigTaskModel;

  constructor(projectModel: ProjectModel, newBigTaskModel: NewBigTaskModel, modalChangeBigTaskModel: ModalChangeBigTaskModel) {
    this.projectModel = projectModel;
    this.newBigTaskModel = newBigTaskModel;
    this.modalChangeBigTaskModel = modalChangeBigTaskModel;
  }

  getBigTasks(projectId: string) {
    axios.get(`/api/project/${projectId}`)
      .then(response => {
        this.projectModel.setBigTasks(response.data.tasks);
        this.projectModel.setColumns(response.data.columns);
      })
      .catch(() => {
        history.push('/projects');
      });
  }

  createBigTask(projectId: string) {
    axios.post('/api/big_task', {
      columnId: 0,
      projectId: projectId,
      id: '',
      info: toJS(this.newBigTaskModel.taskInfo),
    })
      .then(response => {
        const data = response.data;
        const id = data.id;
        const bigTask = {
          columnId: 0,
          projectId,
          id,
          info: toJS(this.newBigTaskModel.taskInfo),
        };
        this.projectModel.addBigTask(bigTask);
        this.newBigTaskModel.clear();
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteBigTask(bigTaskId: string) {
    axios.delete(`/api/big_task?id=${bigTaskId}`, {})
      .then(() => {
        this.projectModel.deleteBigTask(bigTaskId);
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeBigTask() {
    const bigTask = {
      ...toJS(this.modalChangeBigTaskModel.task),
    };

    axios.put('/api/big_task', {
      columnId: bigTask.columnId,
      projectId: bigTask.projectId,
      id: bigTask.id,
      info: bigTask.info,
    })
      .then(response => {
        bigTask.info.subTasks = response.data;
        this.projectModel.updateBigTask(bigTask);
        this.modalChangeBigTaskModel.close();
      })
      .catch(error => {
        console.log(error);
      });
  }

  updateTasks(projectId: string) {
    const tasks = toJS(this.projectModel.tasks);

    axios.put(`/api/project/${projectId}`, tasks)
      .catch(error => {
        console.log(error);
      });
  }
}

export default ProjectService;
