import { observer } from "mobx-react";
import { Fragment, useCallback, useContext } from "react";
import { StoreContext } from "../../../store";
import { BsThreeDots, BsTrash } from "react-icons/bs";
import { FcGallery } from "react-icons/fc";
import history from '../../../history/history';

import cn from 'classnames';
import styles from './ProjectCard.module.css';
import ModalChangeProjectCard from "../ModalChangeProjectCard/ModalChangeProjectCard";

interface Project {
  id: string,
  name: string,
  description: string,
  date: string,
  color: string,
}


function ProjectCard(props: {key: number, project: Project,}) {
  const globalStore = useContext(StoreContext);

  const showProject = useCallback(() => {
    history.push(`/project/${props.project.id}`);
  }, [props.project.id]);

  const deleteProject = useCallback(() => {
    globalStore.services.listOfProjectCardsService.deleteProject(props.project.id);
    // globalStore.models.listsOfProjectsModel.deleteProject(props.project.id);
  }, [globalStore.services.listOfProjectCardsService, props.project.id]);

  const openChangeProjectCard = useCallback(() => {
    globalStore.models.modalChangeProjectCardModel.setProjectCard(props.project);
  }, [globalStore.models.modalChangeProjectCardModel, props.project]);


  const { name, date, color } = props.project;

  return (
    <Fragment>
      <div className={styles.projectCardContainer}>
        <div className={styles.projectCardContent}>
          <header className={styles.projectCardHeader}>
            <div className={cn(styles.projectType, color)} />
            <div className={styles.icons}>
              <BsThreeDots className={styles.showProjectIcon} onClick={openChangeProjectCard} />
              <BsTrash className={styles.deleteProjectIcon} onClick={deleteProject} />
            </div>
          </header>
          <FcGallery className={styles.caseIcon} onClick={showProject} />
          <div className={styles.deadlineContainer}>
            <p className={styles.projectDate}>{date.replaceAll('-', '.')}</p>
          </div>
        </div>
        <div className={styles.projectTitleContainer}>
          <p className={styles.projectTitle}>{globalStore.models.listsOfProjectsModel.truncate(name)}</p>
        </div>
      </div>
      {globalStore.models.modalChangeProjectCardModel.projectCard.id === props.project.id && <ModalChangeProjectCard />}
    </Fragment>
  );
}

export default observer(ProjectCard);
