import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { StoreContext } from '../../store';
import { BsFileCheck, BsFileEarmarkText, BsDoorOpen } from "react-icons/bs";
import Logo from './img/test2.png';

import styles from './NavBar.module.css';
import cn from 'classnames';

function NavBar() {
  const globalStore = useContext(StoreContext);

  const redirectToTasks = useCallback(() => {
    globalStore.services.navBarService.redirectToTasks();
  }, [globalStore.services.navBarService]);

  const redirectToProjects = useCallback(() => {
    globalStore.services.navBarService.redirectToProjects();
  }, [globalStore.services.navBarService]);

  const redirectToLogIn = useCallback(() => {
    globalStore.services.navBarService.redirectToLogIn();
  }, [globalStore.services.navBarService]);

  return (
    <aside className={styles.navigation}>
      <div className={styles.logoContainer}>
        <img alt="Логотип" src={Logo} className={styles.logo} />
        <h1 className={styles.heading}>.tasky</h1>
      </div>
      <div className={styles.linksContainer}>
        <div className={styles.linkContainer} onClick={redirectToTasks}>
          <BsFileCheck className={styles.linkIcon} />
          <p className={styles.linkText}>Задачи</p>
        </div>
        <div className={styles.linkContainer} onClick={redirectToProjects}>
          <BsFileEarmarkText className={styles.linkIcon} />
          <p className={styles.linkText}>Проекты</p>
        </div>
        <div className={styles.linkContainer} onClick={redirectToLogIn}>
          <BsDoorOpen className={styles.linkIcon} />
          <p className={styles.linkText}>Выйти</p>
        </div>
      </div>

      <div className={cn(styles.linksContainer, styles.linksContainer_bottom)}>
      
      </div>
    </aside>
  );
}

export default observer(NavBar);
