import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { StoreContext } from '../store';


function ProtectedRoute({...routeProps}: RouteProps) {
  const globalStore = useContext(StoreContext);

  useEffect(() => {
    globalStore.services.appService.checkToken();
    return () => {
      globalStore.models.protectedRouteModel.load();
    };
  }, [globalStore.models.protectedRouteModel, globalStore.services.appService]);

  if(globalStore.models.protectedRouteModel.isLoaded) {
    if(globalStore.models.protectedRouteModel.isAuth) {
      return <Route {...routeProps} />;
    }
    return <Redirect to="/" />;
  }
  return (<div>Загрузка</div>);
};

export default observer(ProtectedRoute);
