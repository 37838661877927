import { observable, action, makeObservable } from "mobx";

interface Project {
  id: string,
  name: string,
  description: string,
  date: string,
  color: string,
}

class ListOfProjectCardsModel {

  constructor() {
    makeObservable(this);
  }
  
  @observable projects: Project[] = [];

  @action setProjects(projects: Project[]) {
    this.projects = projects;
  }

  @action deleteProjectCards() {
    this.projects = [];
  }

  @action addProject(project: Project) {
    this.projects = [...this.projects, project];
  }

  @action deleteProject(projectId: string) {
    this.projects = this.projects.filter(project => project.id !== projectId);
  }

  @action updateProjectCard(projectCard: Project) {
    const newProjects = [...this.projects];
    const projectInd = newProjects.findIndex(project => project.id === projectCard.id);

    newProjects[projectInd] = projectCard;
    this.projects = newProjects;
  }

  truncate(str: string) {
    return str.length > 15 ? str.substring(0, 14) + "..." : str;
  }

}

export default ListOfProjectCardsModel;
