import { useCallback, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { DragDropContext } from 'react-beautiful-dnd';
import { useRouteMatch } from 'react-router';

import { StoreContext } from "../../../store";

import Column from "../Column/Column";

import styles from './Project.module.css';


function Project() {
  const globalStore = useContext(StoreContext);

  const { params } = useRouteMatch<{ projectId: string }>();

  useEffect(() => {
    globalStore.services.projectService.getBigTasks(params.projectId);
    return () => {
      globalStore.models.projectModel.deleteTasks();
    };
  },[globalStore.models.projectModel, globalStore.services.projectService, params.projectId]);

  const onDragEnd = useCallback((result) => {
    globalStore.models.projectModel.onDragEnd(result);
    globalStore.services.projectService.updateTasks(params.projectId);
  }, [globalStore.models.projectModel, globalStore.services.projectService, params.projectId]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <main className={styles.projectTasks}>
        {globalStore.models.projectModel.columnOrder.map(columnId => {
          const column = globalStore.models.projectModel.columns[columnId];
          // const tasks = column.tasksIds.map(taskId => globalStore.models.projectModel.tasks[taskId]);
          const tasks = column.tasksIds
            .map(taskId => {

              const task = globalStore.models.projectModel.tasks.find(task => task.id === taskId);
              return task === undefined ? {columnId: NaN, projectId: '', id: '', info: {name: '', description: '', date: '', color: '', subTasks: []}} : task;

            }).filter(task => task.id !== '');
          return <Column key={column.id} column={column} tasks={tasks} />;
        })}
      </main>
    </DragDropContext>
  );
}

export default observer(Project);
