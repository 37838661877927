import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import { BsCircle, BsCheckCircleFill } from "react-icons/bs";
import { DebounceInput } from 'react-debounce-input';
import { StoreContext } from "../../../store";

import styles from './Task.module.css';

interface TaskProp {
  id: string,
  listOfTasksId: string,
  status: string,
  text: string,
}


function Task(props: { key: number, task: TaskProp, }) {
  const globalStore = useContext(StoreContext);

  const changeTaskStatus = useCallback(() => {
    globalStore.services.toDoTasksService.changeTaskStatus(props.task);
    // globalStore.models.toDoTasksModel.changeTaskStatus(props.task);
  }, [globalStore.services.toDoTasksService, props.task]);

  const changeTask = useCallback((event) => {
    const newTask = {
      ...props.task,
      text: event.target.value,
    }
    globalStore.services.toDoTasksService.changeTaskName(newTask);
    // globalStore.models.toDoTasksModel.changeTask(newTask);
  }, [globalStore.services.toDoTasksService, props.task]);

  const deleteTask = useCallback(() => {
    globalStore.services.toDoTasksService.deleteTask(props.task);
    // globalStore.models.toDoTasksModel.deleteTask(props.task);
  }, [globalStore.services.toDoTasksService, props.task]);

  return (
    <div className={styles.addTaskNameContainer} onDoubleClick={deleteTask}>
      {props.task.status === 'active' ?
        <BsCircle
          className={styles.addTaskIcon}
          onClick={changeTaskStatus} /> :
        <BsCheckCircleFill className={styles.addTaskIcon} onClick={changeTaskStatus} />}

      {props.task.status === 'active' ?
        <DebounceInput
          className={styles.taskName}
          debounceTimeout={500}
          onChange={changeTask}
          value={props.task.text} /> :
        <del className={styles.taskName}>{props.task.text}</del>}
    </div>
  );
}

export default observer(Task);
