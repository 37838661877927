import { observer } from "mobx-react";
import { useCallback, useContext } from "react";

import cn from 'classnames';
import styles from './ModalChangeBigTask.module.css';

import Button from "../../Button/Button";
import { BsPlusCircle } from "react-icons/bs";
import SubTask from "../SubTask/SubTask";

import { StoreContext } from "../../../store";


function ModalChangeBigTask() {
  const globalStore = useContext(StoreContext);

  const closeModal = useCallback(() => {
    globalStore.models.modalChangeBigTaskModel.close();
  }, [globalStore.models.modalChangeBigTaskModel]);

  const setColor = useCallback((event) => {
    globalStore.models.modalChangeBigTaskModel.setColor(event.currentTarget.dataset.color);
  }, [globalStore.models.modalChangeBigTaskModel]);

  const updateInputs = useCallback((event) => {
    globalStore.models.modalChangeBigTaskModel.updateInfo(event.currentTarget.name, event.currentTarget.value);
  }, [globalStore.models.modalChangeBigTaskModel]);

  const updateBigTask = useCallback(() => {
    globalStore.services.projectService.changeBigTask();
  }, [globalStore.services.projectService]);


  const addSubTask = useCallback((event) => {
    if (event.currentTarget.nextSibling.value) {
      globalStore.models.modalChangeBigTaskModel.addSubTask(event.currentTarget.nextSibling.value);
    }
    event.currentTarget.nextSibling.value = '';
  }, [globalStore.models.modalChangeBigTaskModel]);

  const bigTask = globalStore.models.modalChangeBigTaskModel.task;
  

  return (
    <div className={styles.modal}>
      <main className={styles.modalContent}>
      <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Название</label>
          <input className={styles.inputText} type="text" name="name" value={bigTask.info.name} onChange={updateInputs} />
        </fieldset>

        <fieldset className={cn(styles.inputContainer, styles.textareaContainer)}>
          <label className={styles.label}>Описание</label>
          <textarea className={cn(styles.inputText, styles.textarea)} name="description" value={bigTask.info.description} onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Дедлайн</label>
          <input className={styles.dateInput} type="date" name="date" value={bigTask.info.date} onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Метка</label>
          <div className={styles.colorsContainer}>
            <input className={cn(styles.inputColor, styles.yellow)} type="button" data-color="yellow" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.blue)} type="button" data-color="blue" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.orange)} type="button" data-color="orange" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.pink)} type="button" data-color="pink" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.green)} type="button" data-color="green" onClick={setColor} />
          </div>
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Подзадачи</label>
          <div className={styles.addTaskNameContainer}>
            <BsPlusCircle className={styles.addTaskIcon} onClick={addSubTask} />
            <input className={styles.addTaskName} type="text" placeholder="Название задачи"/>
          </div>

          <div className="tasks">
            {bigTask !== undefined && bigTask.info.subTasks.map((subTask, ind) => <SubTask key={ind} subTask={subTask} />)}
          </div>
        </fieldset>

        <fieldset className={styles.btnsContainer}>
          <div className={styles.btnsGroup}>
            <Button className={styles.closeBtn} onClick={closeModal}>Закрыть</Button>
            <Button className={styles.createBtn} onClick={updateBigTask}>Изменить</Button>
          </div>
        </fieldset>
      </main>

    </div>
  );
}

export default observer(ModalChangeBigTask);
