import axios from 'axios';

import { ListOfProjectCardsModel, ModalChangeProjectCardModel, ModalNewProjectCardModel } from '../models';

interface Inputs {
  name: string,
  description: string,
  date: string,
  color: string,
}

interface Project {
  id: string,
  name: string,
  description: string,
  date: string,
  color: string,
}


class ListOfProjectCardsService {
  private listsOfProjectsModel: ListOfProjectCardsModel;
  private modalNewProjectCardModel: ModalNewProjectCardModel;
  private modalChangeProjectCardModel: ModalChangeProjectCardModel;

  constructor(listsOfProjectsModel: ListOfProjectCardsModel, modalNewProjectCardModel: ModalNewProjectCardModel, modalChangeProjectCardModel: ModalChangeProjectCardModel) {
    this.listsOfProjectsModel = listsOfProjectsModel;
    this.modalNewProjectCardModel = modalNewProjectCardModel;
    this.modalChangeProjectCardModel = modalChangeProjectCardModel;

  }

  getListOfProjectCards() {
    axios.get('/api/project')
      .then(response => {
        this.listsOfProjectsModel.setProjects(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  createProject(projectInfo: Inputs) {
    axios.post('/api/project', {
      id: '',
      name: projectInfo.name,
      description: projectInfo.description,
      date: projectInfo.date,
      color: projectInfo.color,
    })
      .then(response => {
        const data = response.data;
        const id = data.id;
        const newProject = {
          ...projectInfo,
          id,
        };
        this.listsOfProjectsModel.addProject(newProject);
        this.modalNewProjectCardModel.clear();
        this.modalNewProjectCardModel.close();
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteProject(id: string) {
    axios.delete(`/api/project?id=${id}`, {})
      .then(() => {
        this.listsOfProjectsModel.deleteProject(id);
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeProject(project: Project) {
    axios.put('/api/project', {
      id: project.id,
      name: project.name,
      description: project.description,
      date: project.date,
      color: project.color,
    })
      .then(() => {
        this.listsOfProjectsModel.updateProjectCard(project);
        this.modalChangeProjectCardModel.clear();
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export default ListOfProjectCardsService;
