import { observable, action, makeObservable } from "mobx";

class ProtectedRouteModel {
  constructor() {
    makeObservable(this);
  }
  
  @observable isAuth: boolean = false;

  @observable isLoaded: boolean = false;

  @action loaded() {
    this.isLoaded = true;
  }

  @action load() {
    this.isLoaded = false;
  }

  @action authorize() {
    this.isAuth = true;
  }

  @action exit() {
    this.isAuth = false;
  }
}

export default ProtectedRouteModel;
