import { observable, action, makeObservable, toJS } from "mobx";


interface Task {
  id: string,
  listOfTasksId: string,
  status: string,
  text: string,
}

interface ListOfTasks {
  id: string,
  name: string,
  tasks: Task[],
}


class ToDoTasksModel {
  constructor() {
    makeObservable(this);
  }

  @observable listsOfTasks: ListOfTasks[] = [];

  @action setListsOfTasks(listsOfTasks: ListOfTasks[]) {
    this.listsOfTasks = listsOfTasks;
  }

  @action deleteListsOfTasks() {
    this.listsOfTasks = [];
  }

  @action addListOfTasks(task: string, id: string) {
    this.listsOfTasks = [...this.listsOfTasks, { id: id, name: task, tasks: []}];
  }

  @action changeListOfTasksName(listOfTasks: ListOfTasks) {
    const newListsOfTasks = [...this.listsOfTasks];
    const indexOfList = newListsOfTasks.findIndex(list => list.id === listOfTasks.id);

    newListsOfTasks[indexOfList] = listOfTasks;

    this.listsOfTasks = newListsOfTasks;
    console.log(toJS(this.listsOfTasks));
  }

  @action addTask(id: string, listOfTasksId: string, task: string) {
    const newListsOfTasks = [...this.listsOfTasks];
    const indexOfList = newListsOfTasks.findIndex(list => list.id === listOfTasksId);
    newListsOfTasks[indexOfList].tasks = [...newListsOfTasks[indexOfList].tasks, { id, listOfTasksId, status: 'active', text: task }];
    this.listsOfTasks = newListsOfTasks;
  }

  @action changeTaskStatus(task: Task) {
    const newListsOfTasks = [...this.listsOfTasks];
    const indexOfList = newListsOfTasks.findIndex(list => list.id === task.listOfTasksId);

    const arrayOfTasks = newListsOfTasks[indexOfList].tasks;

    const indexOfTask = arrayOfTasks.findIndex( item => item.id === task.id );

    arrayOfTasks[indexOfTask] = task;

    this.listsOfTasks = newListsOfTasks;
  }

  @action changeTask(task: Task) {
    const newListsOfTasks = [...this.listsOfTasks];
    const indexOfList = newListsOfTasks.findIndex(list => list.id === task.listOfTasksId);

    const arrayOfTasks = newListsOfTasks[indexOfList].tasks;

    const indexOfTask = arrayOfTasks.findIndex( item => item.id === task.id );

    arrayOfTasks[indexOfTask] = task;

    this.listsOfTasks = newListsOfTasks;
  }

  @action deleteTask(task: Task) {
    const newListsOfTasks = [...this.listsOfTasks];

    const indOfList = newListsOfTasks.findIndex(list => list.id === task.listOfTasksId);

    const arrayOfTasks = newListsOfTasks[indOfList].tasks;

    newListsOfTasks[indOfList].tasks = arrayOfTasks.filter(item => item.id !== task.id);

    this.listsOfTasks = newListsOfTasks;
  }

  @action deleteListOfTasks(listIndex: string) {
    this.listsOfTasks = this.listsOfTasks.filter(list => list.id !== listIndex);
  }
}

export default ToDoTasksModel;
