import { observable, action, makeObservable } from "mobx";

interface Info {
  name: string,
  userName: string,
  email: string,
  password: string,
}

class SignUpFormModel {
  constructor() {
    makeObservable(this);
  }

  @observable info: Info = {
    name: '',
    userName: '',
    email: '',
    password: '',
  }

  @observable isError = false;

  @action throwError() {
    this.isError = true;
  }

  @action handleError() {
    this.isError = false;
  }

  @action updateInfo(name: string, value: string) {
    this.info = {
      ...this.info,
      [name]: value,
    };
  }

  @action clear() {
    this.info = {
      name: '',
      userName: '',
      email: '',
      password: '',
    }
  }
}

export default SignUpFormModel;
