import { observer } from "mobx-react";
import { useCallback, useContext } from "react";

import cn from 'classnames';
import styles from './ModalChangeProjectCard.module.css';

import Button from "../../Button/Button";

import { StoreContext } from "../../../store";


function ModalChangeProjectCard() {
  const globalStore = useContext(StoreContext);

  const closeModal = useCallback(() => {
    globalStore.models.modalChangeProjectCardModel.clear();
  }, [globalStore.models.modalChangeProjectCardModel]);

  const setColor = useCallback((event) => {
    globalStore.models.modalChangeProjectCardModel.setColor(event.currentTarget.dataset.color);
  }, [globalStore.models.modalChangeProjectCardModel]);

  const updateInputs = useCallback((event) => {
    globalStore.models.modalChangeProjectCardModel.updateProjectCard(event.currentTarget.name, event.currentTarget.value);
  }, [globalStore.models.modalChangeProjectCardModel]);

  const updateProjectCard = useCallback(() => {
    const projectCard = globalStore.models.modalChangeProjectCardModel.projectCard;

    globalStore.services.listOfProjectCardsService.changeProject(projectCard);

    // globalStore.models.listsOfProjectsModel.updateProjectCard(projectCard);
    // globalStore.models.modalChangeProjectCardModel.clear();
  }, [globalStore.models.modalChangeProjectCardModel.projectCard, globalStore.services.listOfProjectCardsService]);


  const projectCard = globalStore.models.modalChangeProjectCardModel.projectCard;
  

  return (
    <div className={styles.modal}>
      <main className={styles.modalContent}>
      <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Название</label>
          <input className={styles.inputText} type="text" name="name" value={projectCard.name} onChange={updateInputs} />
        </fieldset>

        <fieldset className={cn(styles.inputContainer, styles.textareaContainer)}>
          <label className={styles.label}>Описание</label>
          <textarea className={cn(styles.inputText, styles.textarea)} name="description" value={projectCard.description} onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Дедлайн</label>
          <input className={styles.dateInput} type="date" name="date" value={projectCard.date} onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Метка</label>
          <div className={styles.colorsContainer}>
            <input className={cn(styles.inputColor, styles.yellow)} type="button" data-color="yellow" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.blue)} type="button" data-color="blue" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.orange)} type="button" data-color="orange" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.pink)} type="button" data-color="pink" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.green)} type="button" data-color="green" onClick={setColor} />
          </div>
        </fieldset>

        <fieldset className={styles.btnsContainer}>
          <div className={styles.btnsGroup}>
            <Button className={styles.closeBtn} onClick={closeModal}>Закрыть</Button>
            <Button className={styles.createBtn} onClick={updateProjectCard}>Изменить</Button>
          </div>
        </fieldset>
      </main>

    </div>
  );
}

export default observer(ModalChangeProjectCard);
