import React from "react";
import SignInForm from "./SignInForm/SignInForm";
import SignInImage from "./SignInImage/SignInImage";

import styles from './SignIn.module.css';

function SignIn() {
  return (
    <div className={styles.authPage}>
      <SignInImage />
      <SignInForm />
    </div>
  );
}

export default SignIn;
