import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import { BsPlusCircle, BsThreeDots, BsTrash } from "react-icons/bs";
import { DebounceInput } from 'react-debounce-input';

import { StoreContext } from "../../../store";

import Task from "../Task/Task";

import styles from './ListOfTasks.module.css';
import cn from 'classnames';

interface TaskType {
  id: string,
  listOfTasksId: string,
  status: string,
  text: string,
}

interface ListOfTasksProp {
  id: string,
  name: string,
  tasks: TaskType[],
}


function ListsOfTasks(props: { key: number, listOfTasks: ListOfTasksProp }) {
  const globalStore = useContext(StoreContext);

  const addTask = useCallback((event) => {
    if (event.currentTarget.nextSibling.value) {
      globalStore.services.toDoTasksService.createTask(props.listOfTasks.id, event.currentTarget.nextSibling.value);
      // const id = String(props.listOfTasks.tasks.length + 2);

      // globalStore.models.toDoTasksModel.addTask(id, props.listOfTasks.id, event.currentTarget.nextSibling.value);
    }
    event.currentTarget.nextSibling.value = '';
  }, [globalStore.services.toDoTasksService, props.listOfTasks.id]);

  const showTasks = useCallback((event) => {
    const listOfTasks = event.currentTarget.parentElement.parentElement.parentElement;

    listOfTasks.classList.toggle(styles.show);
  }, []);

  const changeListOfTasksName = useCallback((event) => {
    const newListOfTasks = {
      ...props.listOfTasks,
      name: event.target.value,
    };

    globalStore.services.toDoTasksService.changeListOfTasksName(newListOfTasks);

    // globalStore.models.toDoTasksModel.changeListOfTasksName(newListOfTasks);
  }, [globalStore.services.toDoTasksService, props.listOfTasks]);


  const deleteListOfTasks = useCallback(() => {
    globalStore.services.toDoTasksService.deleteListOfTasks(props.listOfTasks.id);

    // globalStore.models.toDoTasksModel.deleteListOfTasks(props.listOfTasks.id);
  }, [globalStore.services.toDoTasksService, props.listOfTasks.id]);

  return (
    <div className={cn(styles.listOfTasks, styles.listOfTasks_withTasks)}>
      <header className={styles.header}>
        <DebounceInput
          className={styles.title}
          debounceTimeout={500}
          onChange={changeListOfTasksName}
          value={props.listOfTasks.name}
        />
        <div className={styles.icons}>
          <BsThreeDots className={styles.showTasksIcon} onClick={showTasks} />
          <BsTrash className={styles.deleteListIcon} onClick={deleteListOfTasks} />
        </div>
      </header>

      <div className={styles.addTaskNameContainer}>
        <BsPlusCircle className={styles.addTaskIcon} onClick={addTask} />
        <input className={styles.addTaskName} type="text" placeholder="Название задачи" />
      </div>

      <div>
        {props.listOfTasks.tasks.map((task, ind) => <Task key={ind} task={task} />)}
      </div>
    </div>
  );
}

export default observer(ListsOfTasks);
