import axios from 'axios';
import { ProtectedRouteModel } from '../models';

class AppService {
  private protectedRouteModel: ProtectedRouteModel;

  constructor(protectedRouteModel: ProtectedRouteModel) {
    this.protectedRouteModel = protectedRouteModel;
  }

  checkToken() {
    axios.get('/api/task_list')
      .then(response => {
        if(Array.isArray(response.data)) {
          this.protectedRouteModel.authorize();
        }
      })
      .catch(() => {
        console.log('Залогинься плиз');
      })
      .then(() => {
        this.protectedRouteModel.loaded();
      });
  }
}

export default AppService;
