import React from "react";

import styles from './SignInImage.module.css';
import cn from 'classnames';

function SignInImage() {
  return (
    <section className={cn(styles.authImage, styles.signInImage)}></section>
  );
}

export default SignInImage;
