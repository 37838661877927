import { useCallback, useContext } from "react";
import { observer } from "mobx-react";
import { Droppable } from 'react-beautiful-dnd';
import { BsPlus, BsBarChartSteps, BsCheckCircleFill } from "react-icons/bs";

import { StoreContext } from "../../../store";

import BigTask from "../BigTask/BigTask";

import styles from './Column.module.css';
import { useRouteMatch } from "react-router";


interface ColumnProp {
  id: number,
  title: string,
  tasksIds: string[],
}

interface TaskInfo {
  name: string,
  description: string,
  date: string,
  color: string,
  subTasks: SubTask[],
}

interface Task {
  projectId: string,
  columnId: number,
  id: string,
  info: TaskInfo,
}

interface SubTask {
  bigTaskId: string,
  id: string,
  status: string,
  text: string,
}


function Column(props: { column: ColumnProp, tasks: Task[] }) {
  const globalStore = useContext(StoreContext);

  const { params } = useRouteMatch<{ projectId: string }>();

  const createBigTask = useCallback(() => {
    if(globalStore.models.newBigTaskModel.taskInfo.name) {
      globalStore.services.projectService.createBigTask(params.projectId);
    }
  }, [globalStore.models.newBigTaskModel.taskInfo.name, globalStore.services.projectService, params.projectId]);

  const updateName = useCallback((event) => {
    globalStore.models.newBigTaskModel.updateName(event.currentTarget.value);
  }, [globalStore.models.newBigTaskModel]);

  return (
    <div className={styles.column}>
      <header className={styles.columnHeader}>
        <div className={styles.columnHeaderContent}>
          <h1 className={styles.title}>{props.column.title}</h1>
          <div className={styles.tasksNumber}>{props.column.tasksIds.length}</div>
        </div>
        <div className={ props.column.id === 0 ? styles.inputContainer : styles.columnInfo}>
          { props.column.id === 0 &&
          <div className={styles.taskNameContainer}>
            <BsPlus className={styles.addIcon} onClick={createBigTask}/>
            <input  className={styles.taskName} type="text" name="name" placeholder="Новая задача" value={globalStore.models.newBigTaskModel.taskInfo.name} onChange={updateName} />
          </div> }
          { props.column.id === 1 && <BsBarChartSteps className={styles.icon} />}
          { props.column.id === 2 && <BsCheckCircleFill className={styles.icon} />}
        </div>
      </header>

      <Droppable droppableId={String(props.column.id)}>
        {(provided) => (
          <div className={styles.columnContent} ref={provided.innerRef} {...provided.droppableProps}>
            {props.tasks.map((task, index) => (
              <BigTask key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default observer(Column);
