import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect } from 'react';
import { BsPlus } from "react-icons/bs";

import { StoreContext } from '../../../store';

import ProjectCard from '../ProjectCard/ProjectCard';
import ModalNewProjectCard from '../ModalNewProjectCard/ModalNewProjectCard';

import styles from './ListOfProjectCards.module.css';


function ListOfProjectCards() {
  const globalStore = useContext(StoreContext);

  useEffect(() => {
    globalStore.services.listOfProjectCardsService.getListOfProjectCards();

    return () => {
      globalStore.models.listsOfProjectsModel.deleteProjectCards();
    };
  },[globalStore.models.listsOfProjectsModel, globalStore.services.listOfProjectCardsService]);

  const openModal = useCallback(() => {
    globalStore.models.modalNewProjectCardModel.open();
  }, [globalStore.models.modalNewProjectCardModel]);

  return (
    <main className={styles.main}>
      <div className={styles.addProjectContainer}>
        <div className={styles.addProjectContent}>
          <BsPlus className={styles.addProjectIcon} onClick={openModal} />
          <p className={styles.addProjectTitle}>Новый проект</p>
        </div>
      </div>

      {globalStore.models.listsOfProjectsModel.projects.map((project, ind) =>
        <ProjectCard key={ind} project={project} />
      )}

      {globalStore.models.modalNewProjectCardModel.isOpen && <ModalNewProjectCard />}
    </main>
  );
}

export default observer(ListOfProjectCards);
