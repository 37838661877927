import React from "react";

import styles from './SignUpImage.module.css';
import cn from 'classnames';

function SignUpImage() {
  return (
    <section className={cn(styles.authImage, styles.signUpImage)}></section>
  );
}

export default SignUpImage;
