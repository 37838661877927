import axios from 'axios';

import history from '../../history/history';

import { SignUpFormModel } from "../models";


class SignUpService {
  private signUpModel: SignUpFormModel;

  constructor(signUpModel: SignUpFormModel) {
    this.signUpModel = signUpModel;
  }

  signUp(event: { preventDefault: () => void; }) {
    event.preventDefault();
    axios.post('/api/user', {
      name: this.signUpModel.info.name,
      nickname: this.signUpModel.info.userName,
      email: this.signUpModel.info.email,
      password: this.signUpModel.info.password,
    })
      .then(() => {
        history.push('/');
      })
      .catch(error => {
        this.signUpModel.throwError();
        console.log(error);
      });
  }
}

export default SignUpService;
