import { observable, action, makeObservable } from "mobx";

interface SubTask {
  bigTaskId: string,
  id: string,
  status: string,
  text: string,
}

interface TaskInfo {
  name: string,
  description: string,
  date: string,
  color: string,
  subTasks: SubTask[],
}

interface Task {
  columnId: number,
  projectId: string,
  id: string,
  info: TaskInfo,
}

class ModalChangeBigTaskModel {
  constructor() {
    makeObservable(this);
  }

  @observable task: Task = {
    columnId: NaN,
    projectId: '',
    id: '',
    info: {
      name: '',
      description: '',
      date: '',
      color: '',
      subTasks: [],
    }
  }

  @action setInfo(task: Task) {
    this.task = task;
  }

  @action updateInfo(type: string, value: string) {
    this.task = {
      ...this.task,
      info: {
        ...this.task.info,
        [type]: value,
      },
    };
  }

  @action setColor(value: string) {
    this.task = {
      ...this.task,
      info: {
        ...this.task.info,
        color: value,
      },
    };
  }

  @action addSubTask(subTask: string) {
    this.task = {
      ...this.task,
      info: {
        ...this.task.info,
        subTasks: [...this.task.info.subTasks, { bigTaskId: this.task.id, id: '', status: 'active', text: subTask }],
      },
    };
  }

  @action changeSubTaskName(newSubTask: SubTask, oldSubTask: SubTask) {
    const updatedTask = {
      ...this.task
    };

    const indexOfSubTask = updatedTask.info.subTasks.findIndex(item => JSON.stringify(item) === JSON.stringify(oldSubTask));

    updatedTask.info.subTasks[indexOfSubTask] = newSubTask;

    this.task = updatedTask;
  }

  @action changeSubTaskStatus(subTask: SubTask) {
    const updatedTask = {
      ...this.task
    };

    const indexOfSubTask = updatedTask.info.subTasks.findIndex(item => JSON.stringify(item) === JSON.stringify(subTask));

    if (indexOfSubTask !== -1) {
      if (updatedTask.info.subTasks[indexOfSubTask].status === 'active') {
        updatedTask.info.subTasks[indexOfSubTask].status = 'done';
      } else {
        updatedTask.info.subTasks[indexOfSubTask].status = 'active';
      }
      this.task = updatedTask;
    }

  }

  @action deleteSubTask(subTask: SubTask) {
    const updatedTask = {
      ...this.task
    };

    updatedTask.info.subTasks = updatedTask.info.subTasks.filter(item => JSON.stringify(item) !== JSON.stringify(subTask));

    this.task = updatedTask;
  }

  @action close() {
    this.task = {
      columnId: NaN,
      projectId: '',
      id: '',
      info: {
        name: '',
        description: '',
        date: '',
        color: '',
        subTasks: [],
      }
    };
  }
}

export default ModalChangeBigTaskModel;
