import { Route, Switch, Router } from "react-router-dom";
import SignUp from "./components/Auth/SignUp/SignUp";
import SignIn from "./components/Auth/SignIn/SignIn";

import ProtectedRoute from "./components/ProtectedRoute";


import newHistory from './history/history';
import store, { StoreContext } from './store';

import "./App.css";
import AuthorizedPage from "./components/Authorized/AuthorizedPage/AuthorizedPage";
import AuthorizedHeader from "./components/Authorized/AuthorizedHeader/AuthorizedHeader";
import ListOfProjectCards from "./components/Authorized/ListOfProjectCards/ListOfProjectCards";
import ToDoTasks from "./components/Authorized/ToDoTasks/ToDoTasks";
import Project from "./components/Authorized/Project/Project";


const App = () => {
  
  return (
    <StoreContext.Provider value={store}>
      <Router history={newHistory}>
        <Switch>
          <Route path="/" exact component={SignIn} />
          <Route path="/signup" exact component={SignUp} />

          <ProtectedRoute
            path="/tasks"
            render={() =>
              <AuthorizedPage
                header={<AuthorizedHeader title='Задачи' />}
                authorizedPage={<ToDoTasks />}
              />}
          />

          <ProtectedRoute
            path="/projects"
            render={() =>
              <AuthorizedPage
                header={<AuthorizedHeader title='Проекты' />}
                authorizedPage={<ListOfProjectCards />}
              />}
          />

          <ProtectedRoute
            path='/project/:projectId'
            render={() =>
              <AuthorizedPage
                header={<AuthorizedHeader title='Проекты' />}
                authorizedPage={<Project />}
              />}
          />
        </Switch>
      </Router>
    </StoreContext.Provider>
  );
};

export default App;
