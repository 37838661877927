import { observable, action, makeObservable } from "mobx";

class AuthorizedHeaderModel {
  constructor() {
    makeObservable(this);
  }
  
  @observable name: string = 'Пользователь';

  @observable nameInTheImg: string = 'П';

  @action setPhoto(name: string) {
    this.nameInTheImg = name.charAt(0).toUpperCase();
  }

  @action setUserName(name: string) {
    this.name = name;
  }

}

export default AuthorizedHeaderModel;
