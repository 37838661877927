import { useCallback, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { StoreContext } from "../../../../store";

import styles from './SignUpForm.module.css';
import cn from 'classnames';


function SignUpForm() {
  const globalStore = useContext(StoreContext);
  
  useEffect(() => {
    return () => {
      globalStore.models.signUpModel.handleError();
      globalStore.models.signUpModel.clear();
    };
  }, [globalStore.models.signInModel, globalStore.models.signUpModel]);

  const updateInfo = useCallback((event) => {
    globalStore.models.signUpModel.updateInfo(event.currentTarget.name, event.currentTarget.value);
  }, [globalStore.models.signUpModel]);

  const handleSubmit = useCallback((event) => {
    globalStore.services.signUpService.signUp(event);
  }, [globalStore.services.signUpService]);

  const isError = globalStore.models.signUpModel.isError;

  return (
    <section className={styles.authFormContainer}>
      <nav className={styles.nav}>
        <div className={styles.linkContainer}>
          <p className={styles.linkText}>
            Уже с нами?
            <Link className={styles.link} to="/"> Войти</Link>
          </p>
        </div>
      </nav>
      <main className={styles.main}>
        <h1 className={styles.heading}>Зарегистрироваться</h1>
        <form className="sign-up-form" onSubmit={handleSubmit}>
          <div className={cn(styles.formField, styles.formFieldGroup)}>
            <div className={cn(styles.inputContainer, styles.userNameInput)}>
              <label className={styles.label} htmlFor="name">Имя</label>
              <input
                className={cn(styles.inputText, isError && styles.inputError)}
                type="text"
                name="name"
                onChange={updateInfo}
                value={globalStore.models.signUpModel.info.name}
                required
              />
            </div>
            <div className={cn(styles.inputContainer, styles.userNameInput)}>
              <label className={styles.label}>Никнейм</label>
              <input
                className={cn(styles.inputText, isError && styles.inputError)}
                type="text"
                name="userName"
                onChange={updateInfo}
                value={globalStore.models.signUpModel.info.userName}
                required
              />
            </div>
          </div>
          <div className={styles.formField}>
            <fieldset className={styles.inputContainer}>
              <label className={styles.label}>Почта</label>
              <input
                className={cn(styles.inputText, isError && styles.inputError)}
                type="email"
                name="email"
                onChange={updateInfo}
                value={globalStore.models.signUpModel.info.email}
                required
              />
            </fieldset>
          </div>
          <div className={styles.formField}>
            <fieldset className={styles.inputContainer}>
              <label className={styles.label}>Пароль</label>
              <input
                className={cn(styles.inputText, isError && styles.inputError)}
                type="password"
                name="password"
                onChange={updateInfo}
                value={globalStore.models.signUpModel.info.password}
                required
              />
            </fieldset>
          </div>
          {isError &&
            <div className={styles.errorField}>
              <p className={styles.errorText}>Такой пользователь уже существует :(</p>
            </div>}
          <div className={cn(styles.formField, styles.btnGroup)}>
            <button className={styles.inputBtn} type="submit">Зарегистрироваться</button>
            {/* <GoogleLoginBtn /> */}
          </div>
        </form>
      </main>
    </section>
  )
}

export default observer(SignUpForm);
