import history from '../../history/history';
import ProtectedRouteModel from '../models/ProtectedRoute';

class GoogleLogInService {
    private protectedRouteModel: ProtectedRouteModel;
  
    constructor(protectedRouteModel: ProtectedRouteModel) {
      this.protectedRouteModel = protectedRouteModel;
    }

    signIn(response: { tokenId: string; }) {
        this.protectedRouteModel.authorize();
        console.log(response);
        const token = response.tokenId;
        console.log("token is " + token);
        history.push('/profile');
    }

    failure() {
      alert('Problem!!!');
    }
}

export default GoogleLogInService;
