import { createContext } from 'react';
import axios from 'axios';

import {
    SignInFormModel,
    SignUpFormModel,
    ProtectedRouteModel,
    AuthorizedHeaderModel,
    ToDoTasksModel,
    ListOfProjectCardsModel,
    ProjectModel,
    ModalNewProjectCardModel,
    ModalChangeBigTaskModel,
    NewBigTaskModel,
    ModalChangeProjectCardModel
} from './models';

import {
    SignInService,
    SignUpService,
    GoogleLogInService,
    GoogleLogOutService,
    NavBarService,
    ToDoTasksService,
    ListOfProjectCardsService,
    ProjectService,
    AppService,
} from './services';

axios.defaults.withCredentials = true;

class GlobalStore {
    models = {
        signInModel: new SignInFormModel(),
        signUpModel: new SignUpFormModel(),
        protectedRouteModel: new ProtectedRouteModel(),
        authorizedHeaderModel: new AuthorizedHeaderModel(),
        toDoTasksModel: new ToDoTasksModel(),
        listsOfProjectsModel: new ListOfProjectCardsModel(),
        projectModel: new ProjectModel(),
        modalNewProjectCardModel: new ModalNewProjectCardModel(),
        newBigTaskModel: new NewBigTaskModel(),
        modalChangeBigTaskModel: new ModalChangeBigTaskModel(),
        modalChangeProjectCardModel: new ModalChangeProjectCardModel(),
    }

    services = {
        appService: new AppService(this.models.protectedRouteModel),
        signInService: new SignInService(this.models.signInModel, this.models.protectedRouteModel),
        signUpService: new SignUpService(this.models.signUpModel),
        googleLogInService: new GoogleLogInService(this.models.protectedRouteModel),
        googleLogOutService: new GoogleLogOutService(),
        navBarService: new NavBarService(this.models.protectedRouteModel),
        toDoTasksService: new ToDoTasksService(this.models.toDoTasksModel),
        listOfProjectCardsService: new ListOfProjectCardsService(this.models.listsOfProjectsModel, this.models.modalNewProjectCardModel, this.models.modalChangeProjectCardModel),
        projectService: new ProjectService(this.models.projectModel, this.models.newBigTaskModel, this.models.modalChangeBigTaskModel),
    }
}

const store = new GlobalStore();

const StoreContext = createContext(store);

export default store;
export { StoreContext };
