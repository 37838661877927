import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import { BsCircle, BsCheckCircleFill } from "react-icons/bs";
import { StoreContext } from "../../../store";

import styles from './SubTask.module.css';

interface SubTaskProp {
  bigTaskId: string,
  id: string,
  status: string,
  text: string,
}

function SubTask(props: { key: number, subTask: SubTaskProp, }) {
  const globalStore = useContext(StoreContext);

  const updateStatus = useCallback(() => {
    globalStore.models.modalChangeBigTaskModel.changeSubTaskStatus(props.subTask);
  }, [globalStore.models.modalChangeBigTaskModel, props.subTask]);

  const changeSubTaskName = useCallback((event) => {
    const newSubTask = {
      ...props.subTask,
      text: event.currentTarget.value,
    };
    globalStore.models.modalChangeBigTaskModel.changeSubTaskName(newSubTask, props.subTask);
  }, [globalStore.models.modalChangeBigTaskModel, props.subTask]);

  const deleteSubTask = useCallback(() => {
    globalStore.models.modalChangeBigTaskModel.deleteSubTask(props.subTask);
  }, [globalStore.models.modalChangeBigTaskModel, props.subTask]);

  return (
    <div className={styles.addTaskNameContainer} onDoubleClick={deleteSubTask}>
      {props.subTask.status === 'active' ? <BsCircle className={styles.addTaskIcon} onClick={updateStatus}/> : <BsCheckCircleFill className={styles.addTaskIcon} onClick={updateStatus}/>}
      {props.subTask.status === 'active' ? <input className={styles.taskName} type="text" onChange={changeSubTaskName} value={props.subTask.text} /> : <del className={styles.taskName}>{props.subTask.text}</del>}
    </div>
  );
}

export default observer(SubTask);