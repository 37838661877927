import { observable, action, makeObservable } from "mobx";

interface TaskInfo {
  name: string,
  description: string,
  date: string,
  color: string,
  subTasks: SubTask[],
}

interface Task {
  projectId: string,
  columnId: number,
  id: string,
  info: TaskInfo,
}

interface SubTask {
  id: string,
  status: string,
  text: string,
  bigTaskId: string,
}

interface Column {
  id: number,
  title: string,
  tasksIds: string[],
}


class ProjectModel {
  constructor() {
    makeObservable(this);
  }

  @observable tasks: Task[] = [];

  @observable columns: Column[] = [{ id: 0, title: 'Сделать', tasksIds: [] }, { id: 1, title: 'В прогрессе', tasksIds: [] }, { id: 2, title: 'Завершены', tasksIds: [] }];

  @observable columnOrder: number[] = [0, 1, 2];

  @action deleteTasks() {
    this.tasks = []
    this.columns = [{ id: 0, title: 'Сделать', tasksIds: [] }, { id: 1, title: 'В прогрессе', tasksIds: [] }, { id: 2, title: 'Завершены', tasksIds: [] }];
  }

  @action onDragEnd(result: { destination: any; source: any; draggableId: any; }) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.columns[Number(source.droppableId)];
    const finish = this.columns[Number(destination.droppableId)];

    if (start === finish) {
      const newTasksIds = Array.from(start.tasksIds);
      newTasksIds.splice(source.index, 1);
      newTasksIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        tasksIds: newTasksIds,
      };

      const newColumns = [...this.columns];
      newColumns[newColumn.id] = newColumn;

      this.columns = newColumns;
      return;
    }

    // Moving from one list to another
    const startTasksIds = Array.from(start.tasksIds);
    startTasksIds.splice(source.index, 1);
    const newStart = {
      ...start,
      tasksIds: startTasksIds,
    };

    const finishTasksIds = Array.from(finish.tasksIds);
    finishTasksIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      tasksIds: finishTasksIds,
    };

    const newColumns = [...this.columns];
    newColumns[newStart.id] = newStart;
    newColumns[newFinish.id] = newFinish;
    this.columns = newColumns;

    const taskInd = this.tasks.findIndex(item => item.id === draggableId);

    const newTasks = [...this.tasks];
    newTasks[taskInd] = {
      ...newTasks[taskInd],
      columnId: +destination.droppableId,
    };

    this.tasks = newTasks;
  }

  @action setBigTasks(bigTasks: Task[]) {
    this.tasks = bigTasks;
  }

  @action setColumns(columns: Column[]) {
    this.columns = columns;
  }

  @action addBigTask(bigTask: Task) {
    this.tasks = [...this.tasks, bigTask];

    const newColumns = [...this.columns];
    newColumns[0].tasksIds = [...newColumns[0].tasksIds, bigTask.id];
    this.columns = newColumns;
  }

  @action deleteBigTask(id: string) {
    this.tasks = this.tasks.filter(task => task.id !== id);
  
    this.columns = this.columns.map(column => {
      return {
        ...column,
        tasksIds: column.tasksIds.filter(taskId => taskId !== id),
      }

    });
  }

  @action updateBigTask(newTask: Task) {
    const indexOfTask = this.tasks.findIndex(task => task.id === newTask.id);
    const updatedTasks = [...this.tasks];
    updatedTasks[indexOfTask] = newTask;
    this.tasks = updatedTasks;
  }

}

export default ProjectModel;
