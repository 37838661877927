import { observable, action, makeObservable } from "mobx";

interface Info {
  login: string,
  password: string,
}


class SignInFormModel {
  constructor() {
    makeObservable(this);
  }
  
  @observable info: Info = {
    login: '',
    password: '',
  }

  @observable isError = false;

  @action throwError() {
    this.isError = true;
  }

  @action handleError() {
    this.isError = false;
  }

  @action updateInfo(name: string, value: string) {
    this.info = {
      ...this.info,
      [name]: value,
    };
  }

  @action clear() {
    this.info = {
      login: '',
      password: '',
    }
  }
}

export default SignInFormModel;
