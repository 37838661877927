import { ReactElement } from 'react';
import NavBar from '../../NavBar/NavBar';

import styles from './AuthorizedPage.module.css';

function AuthorizedPage(props: {
  header: ReactElement | null,
  authorizedPage: ReactElement,
}) {
  return (
    <div className={styles.authorizedPageContainer}>
      <div className={styles.authorizedPage}>
        <NavBar />
        <main className={styles.authorizedMain}>
          {props.header}
          {props.authorizedPage}
        </main>
      </div>
    </div>
  );
}

export default AuthorizedPage;
