import { observable, action, makeObservable } from "mobx";

interface SubTask {
  bigTaskId: string,
  id: string,
  status: string,
  text: string,
}

interface TaskInfo {
  name: string,
  description: string,
  date: string,
  color: string,
  subTasks: SubTask[],
}


class NewBigTaskModel {

  constructor() {
    makeObservable(this);
  }

  @observable taskInfo: TaskInfo = {
    name: '',
    description: '',
    date: '',
    color: '',
    subTasks: [],
  }

  @action updateName(name: string) {
    this.taskInfo = {
      ...this.taskInfo,
      name
    }
  }

  @action clear() {
    this.taskInfo = {
      name: '',
      description: '',
      date: '',
      color: '',
      subTasks: [],
    };
  }
}

export default NewBigTaskModel;
