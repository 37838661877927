import axios from 'axios';
import { toJS } from 'mobx';

import { ToDoTasksModel } from '../models';

interface Task {
  listOfTasksId: string,
  id: string,
  status: string,
  text: string,
}

interface ListOfTasks {
  id: string,
  name: string,
  tasks: Task[],
}

class ToDoTasksService {
  private toDoTasksModel: ToDoTasksModel;

  constructor(toDoTasksModel: ToDoTasksModel) {
    this.toDoTasksModel = toDoTasksModel;
  }

  getListsOfTasks() {
    axios.get('/api/task_list')
      .then(response => {
        this.toDoTasksModel.setListsOfTasks(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  createListOfTasks(listOfTasksName: string) {
    axios.post('/api/task_list', {
      id: '',
      name: listOfTasksName,
      tasks: [],
    })
      .then(response => {
        const data = response.data;
        const id = data.id;
        this.toDoTasksModel.addListOfTasks(listOfTasksName, id);
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteListOfTasks(listOfTasksId: string) {
    axios.delete(`/api/task_list?id=${listOfTasksId}`, {})
      .then(() => {
        this.toDoTasksModel.deleteListOfTasks(listOfTasksId);
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeListOfTasksName(listOfTasks: ListOfTasks) {
    axios.put('/api/task_list', {
      id: listOfTasks.id,
      name: listOfTasks.name,
      tasks: listOfTasks.tasks,
    })
      .then(() => {
        this.toDoTasksModel.changeListOfTasksName(listOfTasks);
      })
      .catch(error => {
        console.log(error);
      });
  }

  createTask(listOfTasksId: string, taskName: string) {
    axios.post('/api/task', {
      id: '',
      listOfTasksId,
      status: 'active',
      text: taskName,
    })
      .then(response => {
        const data = response.data;
        const taskId = data.id;
        this.toDoTasksModel.addTask(taskId, listOfTasksId, taskName);
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteTask(task: Task) {
    axios.delete(`/api/task?id=${task.id}`, {})
      .then(() => {
        this.toDoTasksModel.deleteTask(task);
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeTaskStatus(task: Task) {
    const newTask = {
      ...toJS(task)
    };

    newTask.status === 'active' ? newTask.status = 'done' : newTask.status = 'active';

    axios.put('/api/task', {
     listOfTasksId: newTask.listOfTasksId,
     id: newTask.id,
     status: newTask.status,
     text: newTask.text,
    })
      .then(() => {
        this.toDoTasksModel.changeTaskStatus(newTask);
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeTaskName(task: Task) {
    axios.put('/api/task', {
      listOfTasksId: task.listOfTasksId,
      id: task.id,
      status: task.status,
      text: task.text,
    })
      .then(() => {
        this.toDoTasksModel.changeTask(task);
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export default ToDoTasksService;
