import axios from 'axios';
import history from '../../history/history';
import { SignInFormModel, ProtectedRouteModel } from '../models';

class SignInService {
    private signInModel: SignInFormModel;

    private protectedRouteModel: ProtectedRouteModel;
    
    constructor(signInModel: SignInFormModel, protectedRouteModel: ProtectedRouteModel) {
        this.signInModel = signInModel;
        this.protectedRouteModel = protectedRouteModel;
    }

    signIn(event: { preventDefault: () => void; }) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('username', this.signInModel.info.login);
        formData.append('password', this.signInModel.info.password);

        axios.post('/login', formData, {withCredentials: true,})
          .then(response => {
            this.protectedRouteModel.authorize();
            history.push('/tasks');
          })
          .catch(() => {
            this.signInModel.throwError();
            console.log('Такого пользователя нет');
          });
    }
}

export default SignInService;
