import { observer } from "mobx-react";
import { Draggable } from 'react-beautiful-dnd';
import { Fragment, useCallback, useContext } from "react";
import { BsThreeDots, BsTrash } from "react-icons/bs";

import { StoreContext } from "../../../store";

import styles from './BigTask.module.css';
import cn from 'classnames';
import ModalChangeBigTask from "../ModalChangeBigTask/ModalChangeBigTask";

interface TaskInfo {
  name: string,
  description: string,
  date: string,
  color: string,
  subTasks: SubTask[],
}

interface Task {
  columnId: number,
  projectId: string,
  id: string,
  info: TaskInfo,
}

interface SubTask {
  bigTaskId: string,
  id: string,
  status: string,
  text: string,
}


function BigTask(props: { task: Task, index: number }) {
  const globalStore = useContext(StoreContext);

  const deleteBigTask = useCallback(() => {
    globalStore.services.projectService.deleteBigTask(props.task.id);
    // globalStore.models.projectModel.deleteBigTask(props.task.id);
  }, [globalStore.services.projectService, props.task.id]);

  const openChangeBigTask = useCallback(() => {
    globalStore.models.modalChangeBigTaskModel.setInfo(props.task);
  }, [globalStore.models.modalChangeBigTaskModel, props.task]);


  return (
    <Fragment>
      <Draggable draggableId={props.task.id} index={props.index}>
        {(provided) => (
          <div className={styles.bigTask}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className={styles.projectCardContent}>
              <header className={styles.projectCardHeader}>
                {props.task.info.color && <div className={cn(styles.projectType, props.task.info.color)} />}
                <p className={styles.projectTitle}>{props.task.info.name}</p>
                <div className={styles.icons}>
                  <BsThreeDots className={styles.showProjectIcon} data-id={props.task.id} onClick={openChangeBigTask}/>
                  <BsTrash className={styles.deleteProjectIcon} onClick={deleteBigTask}/>
                </div>
              </header>
              {props.task.info.date &&
                <div className={styles.deadlineContainer}>
                  <p className={styles.projectDate}>{props.task.info.date.replaceAll('-', '.')}</p>
                </div>
              }
            </div>
          </div>
        )}
      </Draggable>
      {globalStore.models.modalChangeBigTaskModel.task.id === props.task.id && <ModalChangeBigTask />}
    </Fragment>
  );
}

export default observer(BigTask);
