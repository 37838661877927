import history from '../../history/history';

class GoogleLogOutService {
   
    logOut() {
        history.push('/');
    }

    failure() {
      alert('Problem!!!');
    }
}

export default GoogleLogOutService;
