import React from "react";
import SignUpForm from "./SignUpForm/SignUpForm";
import SignUpImage from "./SignUpImage/SignUpImage";

import styles from "./SignUp.module.css";

function SignUp() {
  return (
    <div className={styles.authPage}>
      <SignUpImage />
      <SignUpForm />
    </div>
  );
}

export default SignUp;
