import axios from 'axios';
import history from '../../history/history';
import { ProtectedRouteModel } from '../models';

class NavBarService {
    private protectedRouteModel: ProtectedRouteModel;
    
    constructor(protectedRouteModel: ProtectedRouteModel) {
        this.protectedRouteModel = protectedRouteModel;
    }

    redirectToTasks() {
        this.protectedRouteModel.authorize();
        history.push('/tasks');
    }

    redirectToProjects() {
        this.protectedRouteModel.authorize();
        history.push('/projects');
    }

    redirectToLogIn() {
      axios.get('/logout')
      .then(() => {
        this.protectedRouteModel.exit();
        this.protectedRouteModel.load();
        history.push('/');
      })
      .catch(error => {
        console.log(error);
      });
    }
}

export default NavBarService;
