import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import { StoreContext } from "../../../store";

import cn from 'classnames';
import styles from './ModalNewProjectCard.module.css';

import Button from "../../Button/Button";


function ModalNewProjectCard() {
  const globalStore = useContext(StoreContext);

  const closeModal = useCallback(() => {
    globalStore.models.modalNewProjectCardModel.close();
  }, [globalStore.models.modalNewProjectCardModel]);

  const setColor = useCallback((event) => {
    globalStore.models.modalNewProjectCardModel.setColor(event.currentTarget.dataset.color);
  }, [globalStore.models.modalNewProjectCardModel]);

  const updateInputs = useCallback((event) => {
    globalStore.models.modalNewProjectCardModel.updateInputs(event.currentTarget.name, event.currentTarget.value);
  }, [globalStore.models.modalNewProjectCardModel]);

  const addProject = useCallback(() => {
    const projectInfo = globalStore.models.modalNewProjectCardModel.inputs;

    if (projectInfo.name && projectInfo.description && projectInfo.date && projectInfo.color) {
      globalStore.services.listOfProjectCardsService.createProject(projectInfo);
    }
  }, [globalStore.models.modalNewProjectCardModel.inputs, globalStore.services.listOfProjectCardsService]);

  return (
    <div className={styles.modal}>
      <main className={styles.modalContent}>
        <h1 className={styles.heading}>Новый проект</h1>
        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Название</label>
          <input className={styles.inputText} type="text" name="name" onChange={updateInputs} />
        </fieldset>

        <fieldset className={cn(styles.inputContainer, styles.textareaContainer)}>
          <label className={styles.label}>Краткое описание</label>
          <textarea className={cn(styles.inputText, styles.textarea)} name="description" onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Дедлайн</label>
          <input className={styles.dateInput} type="date" name="date" onChange={updateInputs} />
        </fieldset>

        <fieldset className={styles.inputContainer}>
          <label className={styles.label}>Метка</label>
          <div className={styles.colorsContainer}>
            <input className={cn(styles.inputColor, styles.yellow)} type="button" data-color="yellow" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.blue)} type="button" data-color="blue" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.orange)} type="button" data-color="orange" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.pink)} type="button" data-color="pink" onClick={setColor} />
            <input className={cn(styles.inputColor, styles.green)} type="button" data-color="green" onClick={setColor} />
          </div>
        </fieldset>

        <fieldset className={styles.btnsContainer}>
          <div className={styles.btnsGroup}>
            <Button className={styles.closeBtn} onClick={closeModal}>Закрыть</Button>
            <Button className={styles.createBtn} onClick={addProject}>Создать</Button>
          </div>
        </fieldset>

      </main>

    </div>
  );
}

export default observer(ModalNewProjectCard);
