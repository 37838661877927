import { observer } from 'mobx-react';
import { useContext } from 'react';
import { StoreContext } from '../../../store';

import styles from './AuthorizedHeader.module.css';


function AuthorizedHeader(props: { title: string; }) {
  const globalStore = useContext(StoreContext);
  
  return (
    <header className={styles.authorizedHeader}>
      <h1 className={styles.title}>{props.title}</h1>
      <div className={styles.userNameContainer}>
        <p className={styles.linkText}>{globalStore.models.authorizedHeaderModel.name}</p>
        <div className={styles.userImg}>
          <p className={styles.linkText}>{globalStore.models.authorizedHeaderModel.nameInTheImg}</p>
        </div>
      </div>
    </header>
  );
}

export default observer(AuthorizedHeader);
