import React, { useCallback, useContext, useEffect } from 'react';
import { StoreContext } from '../../../store';
import { BsPlusSquareDotted } from "react-icons/bs";
import ListOfTasks from '../ListOfTasks/ListOfTasks';
import { observer } from 'mobx-react-lite';

import styles from './ToDoTasks.module.css';


function ToDoTasks() {
  const globalStore = useContext(StoreContext);

  useEffect(() => {
    globalStore.services.toDoTasksService.getListsOfTasks();

    return () => {
      globalStore.models.toDoTasksModel.deleteListsOfTasks();
    };
  },[globalStore.models.toDoTasksModel, globalStore.services.toDoTasksService]);

  const addListOfTasks = useCallback((event) => {
    if (event.currentTarget.previousSibling.value) {
      globalStore.services.toDoTasksService.createListOfTasks(event.currentTarget.previousSibling.value);
      // const id = String(globalStore.models.toDoTasksModel.listsOfTasks.length + 2);
      // globalStore.models.toDoTasksModel.addListOfTasks(event.currentTarget.previousSibling.value, id);
    }
    event.currentTarget.previousSibling.value = '';
  }, [globalStore.services.toDoTasksService]);

  const listsOfTasks = globalStore.models.toDoTasksModel.listsOfTasks.map((listOfTasks, ind) => 
    <ListOfTasks key={ind} listOfTasks={listOfTasks} />
  );
  
  return (
    <main className={styles.todoTasksContainer}>
      <div className={styles.listOfTasks}>
        <input className={styles.listOfTasksName} type="text" placeholder="Название нового списка задач"/>
        <BsPlusSquareDotted className={styles.addIcon} onClick={addListOfTasks}/>
      </div>

      <div>
        { listsOfTasks }
      </div>
     
    </main>
  );
}

export default observer(ToDoTasks);

