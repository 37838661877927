import { observable, action, makeObservable } from "mobx";


interface ProjectCard {
  id: string,
  name: string,
  description: string,
  date: string,
  color: string,
}


class ModalChangeProjectCardModel {
  constructor() {
    makeObservable(this);
  }
  
  @observable projectCard: ProjectCard = {
    id: '',
    name: '',
    description: '',
    date: '',
    color: '',
  };

  @action setProjectCard(newProjectCard: ProjectCard) {
    this.projectCard = newProjectCard;
  }

  @action updateProjectCard(type: string, value: string) {
    this.projectCard = {
      ...this.projectCard,
      [type]: value,
    };
  }

  @action setColor(color: string) {
    this.projectCard = {
      ...this.projectCard,
      color,
    }
  }

  @action clear() {
    this.projectCard = {
      id: '',
      name: '',
      description: '',
      date: '',
      color: '',
    };
  }

}

export default ModalChangeProjectCardModel;
